import { FC, useContext, useEffect, useState } from 'react';

import Axios from 'axios';
import { Portal } from 'react-portal';

// Components:
import { ADialog, ASimple, Button, Heading, Icon } from 'atoms';
import { LoadingSpinner } from 'components';
import { ScreenshotData } from 'page/Editor/Scene';

// Context:
import { AuthContext, EditorContext, LangContext } from 'context';

// Languages:
import Localizations, { Static } from 'languages';

// Partials:
import { Send, Sharing, TabBar } from './Partials';

// Styles:
import './style.scss';

// Utility:
import { getMasterlinesAsArray, post } from 'utils';

// Consts:
const DELAY = 250;
const DURATION = 150;

// ===================================================================
interface Props {
  open: boolean;
  close: any;
  showPrices: boolean;
}

interface CalculatedDevice {
  count: number;
  code: string;
  description: string;
  price: string;
}
interface SubDataWithImage {
  id: string;
  description: string;
  product: CalculatedDevice[];
  images?: {
    top: string;
    viewA: string;
    viewB: string;
  };
  text?: string;
  totalPrice: string;
  type: string;
}

type Result = 'success' | 'error' | 'secret' | '';

// ===================================================================
export const ShareDialog: FC<Props> = ({ showPrices, open, close }) => {
  const { lang } = useContext(LangContext);
  const { userEmail } = useContext(AuthContext);
  const { configuration, savedConfiguration, scene } = useContext(EditorContext);

  const [tab, setTab] = useState<'share' | 'send' | 'download'>('share');
  const [loading, setLoading] = useState<boolean>(false);
  const [sendEmailResult, setSendEmailResult] = useState<Result>('');

  /* Share */

  /* Switch Collection */
  const [sketch, setSketch] = useState<boolean>(false);
  const [offering] = useState<boolean>(true);
  const [view] = useState<boolean>(true);
  const [offeringWithPrice] = useState<boolean>(false);

  /* Sending Tab */
  const [message, setMessage] = useState<string>('');
  const [emails, setEmails] = useState<string>('');
  const [allowContact, setAllowContact] = useState<boolean>(false);

  useEffect(() => {
    setEmails('');
    setMessage('');
    setAllowContact(false);
    setSendEmailResult('');
  }, [open]);

  const handleSendConfiguration = async () => {
    setSendEmailResult('');
    if (scene && savedConfiguration) {
      setLoading(true);

      const addresses = emails.split(',');

      scene.setSelected(null);

      let subData: SubDataWithImage[] = [];
      let totalPrice = 0;
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/sharing/calculate/configuration`, {
        data: {
          language: lang,
          json: configuration.exportJSON()
        }
      });
      if (data) {
        subData = data.subData || [];
        totalPrice = data.totalPrice;
      }
      if (error) {
        console.log(error);
      }

      let introduction = '';
      Static.forEach((s, index) => {
        if (s[lang].length > 0) {
          introduction += `${s[lang]}${index < Static.length ? '\n\n' : ''}`;
        }
      });
      let flexiCount = 0;
      let spaceCount = 0;
      let modularCount = 0;
      let modularNOLCount = 0;
      let masterlineCount = 0;
      let marineCount = 0;
      const printCount = getMasterlinesAsArray(configuration).length + configuration.getModular().length + configuration.getModularNOL().length;
      const printQuallity = printCount <= 2 ? 4 : printCount <= 4 ? 3 : 2;

      const prepareSubdata = (sD: SubDataWithImage) => {
        return new Promise((resolve, reject) => {
          /* Get Images for Block */
          const block =
            sD.type === 'Modular'
              ? configuration.getModular()[modularCount]
              : sD.type === 'ModularNOL'
              ? configuration.getModularNOL()[modularNOLCount]
              : sD.type === 'FlexiChef'
              ? configuration.getFlexiChefs()[flexiCount]
              : sD.type === 'SpaceCombi'
              ? configuration.getSpaceCombis()[spaceCount]
              : sD.type === 'MarineMeister'
              ? configuration.getMarineMeister()[marineCount]
              : getMasterlinesAsArray(configuration)[masterlineCount];

          if (sD.type === 'Modular') modularCount += 1;
          if (sD.type === 'ModularNOL') modularNOLCount += 1;
          if (sD.type === 'FlexiChef') flexiCount += 1;
          if (sD.type === 'SpaceCombi') spaceCount += 1;
          if (sD.type === 'Masterline' && masterlineCount === 0) {
            sD.text = introduction;
          }
          if (sD.type === 'Masterline') {
            const masterlineBlock = configuration.getMasterline()[masterlineCount];
            if (masterlineCount === 0) {
              sD.text += '\n\n';
            } else {
              sD.text = '';
            }
            sD.text += masterlineBlock.getDescription() ?? '';
            masterlineCount += 1;

            console.log('Installation Wall Device:', masterlineBlock.getInstallationWallDevice());
            if (!!masterlineBlock.getInstallationWallDevice()) {
              sD.text += '\n\n';
              sD.text += Localizations['installationWallHint'][lang];
              sD.text += '\n\n';
            }
          }
          if (sD.type === 'MarineMeister') marineCount += 1;
          if (sD.type === 'Modular' || sD.type === 'ModularNOL' || sD.type === 'Masterline' || sD.type === 'MarineMeister') {
            scene.screenshot(
              block,
              (screenshot: ScreenshotData) => {
                sD.images = {
                  top: screenshot.top,
                  viewA: screenshot.viewA,
                  viewB: screenshot.viewB
                };
                resolve(sD);
              },
              printQuallity
            );
          } else {
            resolve(null);
          }
        }).then(result => result);
      };

      Promise.all(subData.map(s => prepareSubdata(s))).finally(async () => {
        const { customer, location, name } = savedConfiguration;

        const handleSendEmail = async (address: string) => {
          // Set payload
          const payload = !sketch
            ? {
                id: savedConfiguration.id,
                receiver: address,
                text: message,
                user: userEmail,
                language: lang,
                customer,
                location,
                name,
                translations: {
                  introduction: configuration.getIntro(),
                  tableHeadline: Localizations['functionalDevices'][lang],
                  tableHeaderCount: Localizations['amount'][lang],
                  tableHeaderCode: Localizations['mknCode'][lang],
                  tableHeaderDescription: Localizations['article'][lang],
                  tableHeaderPrice: `${Localizations['price'][lang]} (EUR)`,
                  footerPage: Localizations['page'][lang],
                  footerOf: Localizations['pageOf'][lang],
                  tableFooterTotalPrice: `${Localizations['totalPrice'][lang]}:`,
                  qrText: Localizations['qrText'][lang]
                },
                subData,
                totalPrice,
                showPrices
              }
            : {
                id: savedConfiguration.id,
                receiver: address,
                text: message,
                user: userEmail,
                language: lang,
                customer,
                location,
                name,
                translations: {
                  introduction: configuration.getIntro(),
                  tableHeadline: Localizations['functionalDevices'][lang],
                  tableHeaderCount: Localizations['amount'][lang],
                  tableHeaderCode: Localizations['mknCode'][lang],
                  tableHeaderDescription: Localizations['article'][lang],
                  tableHeaderPrice: `${Localizations['price'][lang]} (EUR)`,
                  footerPage: Localizations['page'][lang],
                  footerOf: Localizations['pageOf'][lang],
                  tableFooterTotalPrice: `${Localizations['totalPrice'][lang]}:`,
                  qrText: Localizations['qrText'][lang]
                },
                subData,
                showPrices,
                totalPrice,
                addSketch: true,
                json: configuration.exportJSON()
              };

          const { data, error } = await post(`${process.env.REACT_APP_API_URL}/sharing/email/configuration`, {
            data: payload
          });
          if (data) {
            return 'success';
          }
          if (error) {
            console.log(error);
            return 'error';
          }
          return '';
        };

        Promise.all(
          addresses.map(a =>
            handleSendEmail(a.trim()).then(val => {
              if (val === 'error') {
                setSendEmailResult('error');
              } else if (sendEmailResult !== 'error') {
                setSendEmailResult('success');
              }
              setLoading(false);
            })
          )
        );
      });
    }
  };

  const handleDownloadDXF = async () => {
    setLoading(true);
    if (scene) {
      await new Promise(async resolve => {
        scene.setSelected(null);
        let introduction = '';
        Static.forEach((s, index) => {
          if (s[lang].length > 0) {
            introduction += `${s[lang]}${index < Static.length ? '\n\n' : ''}`;
          }
        });

        const { customer, location, id } = savedConfiguration;

        // Get Print Key
        var { data, error } = await post(`${process.env.REACT_APP_API_URL}/sharing/print/sketch`, {
          data: {
            id,
            user: 'User.',
            language: lang,
            customer,
            location,
            json: configuration.exportJSON()
          }
        });

        if (data) {
          const response = await Axios.get(`${process.env.REACT_APP_API_URL}/sharing/print/dxf/${data.key}`, {
            withCredentials: true,
            headers: {
              jwt: window.localStorage.getItem('jwt')
            },
            responseType: 'blob'
          });

          try {
            var blob = new Blob([response.data], { type: response.headers['content-type'] });
            var link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = savedConfiguration.name + '.zip';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (exc) {
            console.log('Saving file failed:', exc);
          }
        }
        if (error) console.log('Error printing the Config:', error);
        setLoading(false);

        resolve(null);
      });
      setLoading(false);
    }
  };

  const handlePrintConfiguration = async () => {
    if (scene) {
      let subData: SubDataWithImage[] = [];
      let totalPrice = 0;
      setLoading(true);
      const { data, error } = await post(`${process.env.REACT_APP_API_URL}/sharing/calculate/configuration`, {
        data: {
          language: lang,
          json: configuration.exportJSON()
        }
      });
      if (data) {
        subData = data.subData || [];
        totalPrice = data.totalPrice;
      }
      if (error) {
        console.log(error);
      }

      scene.setSelected(null);

      let introduction = '';
      Static.forEach((s, index) => {
        if (s[lang].length > 0) {
          introduction += `${s[lang]}${index < Static.length ? '\n\n' : ''}`;
        }
      });
      let flexiCount = 0;
      let spaceCount = 0;
      let modularCount = 0;
      let modularNOLCount = 0;
      let masterlineCount = 0;
      let marineMeisterCount = 0;

      const printCount = getMasterlinesAsArray(configuration).length + configuration.getModular().length + configuration.getModularNOL().length;
      const printQuallity = printCount <= 2 ? 4 : printCount <= 4 ? 3 : 2;

      const prepareSubdata = (sD: SubDataWithImage) => {
        return new Promise((resolve, reject) => {
          /* Get Images for Block */
          const block =
            sD.type === 'Modular'
              ? configuration.getModular()[modularCount]
              : sD.type === 'ModularNOL'
              ? configuration.getModularNOL()[modularNOLCount]
              : sD.type === 'FlexiChef'
              ? configuration.getFlexiChefs()[flexiCount]
              : sD.type === 'SpaceCombi'
              ? configuration.getSpaceCombis()[spaceCount]
              : sD.type === 'MarineMeister'
              ? configuration.getMarineMeister()[marineMeisterCount]
              : getMasterlinesAsArray(configuration)[masterlineCount];

          if (sD.type === 'Modular') modularCount += 1;
          if (sD.type === 'ModularNOL') modularNOLCount += 1;
          if (sD.type === 'FlexiChef') flexiCount += 1;
          if (sD.type === 'SpaceCombi') spaceCount += 1;
          if (sD.type === 'MarineMeister') marineMeisterCount += 1;
          if (sD.type === 'Masterline' && masterlineCount === 0) {
            sD.text = introduction;
          }
          if (sD.type === 'Masterline') {
            const masterlineBlock = configuration.getMasterline()[masterlineCount];
            if (masterlineCount === 0) {
              sD.text += '\n\n';
            } else {
              sD.text = '';
            }
            sD.text += masterlineBlock.getDescription() ?? '';
            masterlineCount += 1;

            console.log('Installation Wall Device:', masterlineBlock.getInstallationWallDevice());

            if (!!masterlineBlock.getInstallationWallDevice()) {
              sD.text += '\n\n';
              sD.text += Localizations['installationWallHint'][lang];
              sD.text += '\n\n';
            }
          }
          if (sD.type === 'Modular' || sD.type === 'ModularNOL' || sD.type === 'Masterline' || sD.type === 'MarineMeister') {
            scene.screenshot(
              block,
              (screenshot: ScreenshotData) => {
                sD.images = {
                  top: screenshot.top,
                  viewA: screenshot.viewA,
                  viewB: screenshot.viewB
                };
                resolve(sD);
              },
              printQuallity
            );
          } else {
            resolve(null);
          }
        }).then(result => result);
      };

      Promise.all(subData.map(s => prepareSubdata(s))).finally(async () => {
        const { id, customer, location, name } = savedConfiguration;
        // Get Print Key
        var { data, error } = await post(`${process.env.REACT_APP_API_URL}/sharing/print/configuration`, {
          data: {
            user: userEmail,
            id,
            language: lang,
            customer,
            location,
            name,
            subData,
            translations: {
              introduction: configuration.getIntro() + '\n\n',
              qrText: Localizations['qrText'][lang],
              tableHeadline: Localizations['functionalDevices'][lang],
              tableHeaderCount: Localizations['amount'][lang],
              tableHeaderCode: Localizations['mknCode'][lang],
              tableHeaderDescription: Localizations['article'][lang],
              tableHeaderPrice: `${Localizations['price'][lang]} (EUR)`,
              footerPage: Localizations['page'][lang],
              footerOf: Localizations['pageOf'][lang],
              tableFooterTotalPrice: `${Localizations['totalPrice'][lang]}:`
              /* offerHint: Localizations['offerHint'][lang] */
            },
            totalPrice,
            showPrices
          }
        });

        if (data) {
          const response = await Axios.get(`${process.env.REACT_APP_API_URL}/sharing/print/pdf/${data.key}`, {
            withCredentials: true,
            headers: {
              jwt: window.localStorage.getItem('jwt')
            },
            responseType: 'blob'
          });

          try {
            var blob = new Blob([response.data], { type: response.headers['content-type'] });
            var link = window.document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = savedConfiguration.name + '.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (exc) {
            console.log('Saving file failed:', exc);
          }
        }
        if (error) console.log('Error printing the Config:', error);
        setLoading(false);
      });
    }
  };

  const handleStlDownload = () => {
    scene.startSTLDownload();
  };

  /* Render */
  return (
    <Portal>
      {/* Background */}
      {open && <ASimple key="ShareDialog-Background" className="ShareDialog-Background" style={{ height: window.innerHeight }} onClick={close} />}

      {/* Content */}
      {open && (
        <ADialog key="ShareDialog-Content" className="ShareDialog-Content" topExit="0" topEnter="50%">
          {/* Close Icon */}
          <div className="ShareDialog-Content-Icon">
            <Icon type="close" size="1.25rem" color="grey" onClick={close} />
          </div>

          {/* Main */}
          <div className="ShareDialog-Content-Main">
            <Heading level="1" color="dark">
              {Localizations['yourConfig'][lang]}
            </Heading>

            {/* TODO: Tab Buttons */}
            <TabBar tab={tab} setTab={setTab} />

            {/* TODO: Share */}
            {/* Sharing Tab */}
            {tab === 'share' && (
              <ASimple key="ShareDialog-Content-Main-Sharing" className="ShareDialog-Content-Main-Sharing" duration={DURATION} delay={DELAY}>
                <Sharing />
              </ASimple>
            )}

            {tab === 'send' && (
              <ASimple key="ShareDialog-Content-Main-Send" className="ShareDialog-Content-Main-Send" duration={DURATION} delay={DELAY}>
                <Send
                  message={message}
                  setMessage={(message: string) => setMessage(message)}
                  emails={emails}
                  sketch={sketch}
                  setSketch={setSketch}
                  setEmails={(emails: string) => setEmails(emails)}
                  allowContact={allowContact}
                  setAllowContact={setAllowContact}
                />
              </ASimple>
            )}

            {/* Buttons for all three Tabs */}
            {tab === 'share' && (
              <ASimple key="ShareDialog-Button-Share" className="text-center" duration={DURATION} delay={DELAY}>
                {/* <div
                      className="flex text-center justify-center align-center mb-3"
                      style={{
                        padding: '1rem 2rem'
                      }}
                    >
                      <Switch
                        status={configIsPublic}
                        margin="0"
                        shadow
                        toggleStatus={() => {
                          handlePublishConfiguration(!configIsPublic);
                          setConfigIsPublic(!configIsPublic);
                        }}
                      />
                      <span className="ml-2">{Localizations['configPublished'][lang]}</span>
                    </div> */}
                {/* <div style={{ width: '350px' }}>
                      <Paragraph color="greyText" fontSize=".9rem" margin="0 0 2rem" lineHeight="18px">
                        <span>
                          <b>{Localizations['warning'][lang] + ':'} </b>
                        </span>
                        {Localizations['notificationMessage'][lang]}
                      </Paragraph>
                    </div> */}
                {/* <Button
                      btnType="third"
                      disabled={configuration.public}
                      maxWidth={400}
                      onClick={publishConfiguration}
                      padding=".65rem 2rem"
                      fontSize=".8rem"
                    >
                      {loading ? <LoadingSpinner size="1rem" /> : Localizations['makeConfigPublic'][lang]}
                    </Button> */}
              </ASimple>
            )}

            {tab === 'send' && (
              <ASimple key="ShareDialog-Button-Send" duration={DURATION} delay={DELAY} style={{ textAlign: 'center', width: '60%' }}>
                <p className="w-100 text-sm m-auto" style={{ height: '26px' }}>
                  {sendEmailResult === 'success' ? Localizations['emailSent'][lang] : sendEmailResult === 'error' ? Localizations['emailSendError'][lang] : ''}
                </p>
                <div className="flex w-100 justify-center">
                  <Button
                    btnType="third"
                    /*                         disabled={(!sketch && !offering && !view && !offeringWithPrice) || (!emails && emails.length < 1)}
                     */ onClick={handleSendConfiguration}
                    margin="0 0 2rem"
                    padding=".65rem 2rem"
                    fontSize=".8rem"
                    width="150px"
                  >
                    {loading ? <LoadingSpinner size="1rem" /> : Localizations['send'][lang]}
                  </Button>
                  {/* <Paragraph color="greyText" fontSize=".9rem" margin="0 0 2rem 1.5rem" lineHeight="18px">
                        <span>
                          <b>{Localizations['warning'][lang] + ':'} </b>
                        </span>
                        {Localizations['notificationMessage'][lang]}
                      </Paragraph> */}
                </div>
              </ASimple>
            )}

            {tab === 'download' && (
              <ASimple key="ShareDialog-Button-Download" duration={DURATION} delay={DELAY} style={{ margin: '3rem 0 0' }}>
                {/* <div
                      style={{
                        display: 'flex',
                        padding: '1rem 0rem',
                        marginBottom: '1.5rem',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                      {/* <Switch
                        status={sketch}
                        disabled
                        margin="0"
                        shadow
                        toggleStatus={() => {
                          setSketch(!sketch);
                        }}
                      />
                      <span style={{ marginLeft: '1rem' }}>{Localizations['addSketch'][lang]}</span>
                    </div> */}
                <div className="flex mb-3 align-center text-center w-100">
                  <Button
                    btnType="third"
                    disabled={loading || !configuration || (!sketch && !offering && !view && !offeringWithPrice)}
                    onClick={handleDownloadDXF}
                    padding=".65rem 1rem"
                    fontSize=".8rem"
                  >
                    {loading ? <LoadingSpinner size="1rem" /> : `DXF ${Localizations['file'][lang]}`}
                  </Button>
                </div>
                <Button
                  btnType="third"
                  disabled={loading || !configuration || (!sketch && !offering && !view && !offeringWithPrice)}
                  onClick={handlePrintConfiguration}
                  padding=".65rem 2rem"
                  fontSize=".8rem"
                >
                  {loading ? <LoadingSpinner size="1rem" /> : `PDF ${Localizations['file'][lang]}`}
                </Button>
                <div className="mt-3">
                  <Button
                    btnType="third"
                    disabled={loading || !configuration || (!sketch && !offering && !view && !offeringWithPrice)}
                    onClick={handleStlDownload}
                    padding=".65rem 2rem"
                    fontSize=".8rem"
                  >
                    {loading ? <LoadingSpinner size="1rem" /> : `STL ${Localizations['file'][lang]}`}
                  </Button>
                </div>
              </ASimple>
            )}
          </div>
        </ADialog>
      )}
    </Portal>
  );
};
