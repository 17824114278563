export enum Type {
  'Device',
  'Equipment',
  'Option',
  'Special'
}

export enum Subtype {
  /**
   * Flügeltür
   */
  WingedDoor,
  /**
   * Hygiene
   */
  Hygiene,
  /**
   * Winkelschiene
   */
  AngularRail,
  /**
   * Kastenschublade OB
   */
  UpperDrawer,
  /**
   * Kastenschublade UB
   */
  LowerDrawer,
  /**
   * Wärmeschrank
   */
  HeatingCabinet,
  /**
   * Wärmeschublade
   */
  HeatingDrawer,
  /**
   * Steckdose OB
   */
  UpperPowerSocket,
  /**
   * Mischbatterie in Abdeckung
   */
  MixingFaucet,
  /**
   * Entleerungssystem
   */
  EmptyingSystem,
  /**
   * E-Backofen
   */
  EOven,
  /**
   * G-Backofen
   */
  GOven,
  /**
   * Handlauf
   */
  HandRail,
  /**
   * Bord über Modul
   */
  Shelf,
  /*
   Bottom
   */
  Bottom,
  /* 
  Border
  */
  Border,
  /*
  Calculation
 */
  Calculation,
  /**
   * Untergestell für Kombidämpfer
   */
  Underframe850,
  Underframe580,
  /**
   * Kühlung
   */
  Cooling,
  /**
   * Durchgehen offener Unterbau
   */
  OpenSubstructure,
  /**
   * Abfallschublade
   */
  WasteDrawer,
  /**
   * Kalt-Wasserzulauf
   */
  ColdWaterInlet,
  /**
   * Beidseitige Bedienung
   */
  DoubleSidedOperation,
  /**
   * Kann eine Mischnatterie am Bord über dem Modul platziert werden?
   */
  ShelfMixingFaucet,
  /**
   * Installationswand
   */
  InstallationWall,
  /**
   * Blindblende
   */
  BlindCover,
  /**
   * Sockelabdeckboden
   */
  BaseCover,
  /**
   * ...
   */
  ReadyXpress,
  /**
   * ...
   */
  SpaceClean,
  /**
   * ...
   */
  MagicHood,
  /**
   * ...
   */
  FlexiCombiAir,
  /**
   * GN Gestell
   */
  GNFrame,
  /**
   * Warmhalteschublade
   */
  WarmingDrawer,
  /**
   * Warmhalregerät
   */
  WarmingDevice,
  /**
   * Zwischenboden
   */
  IntermediatePlate,
  /**
   * FlexiCombiDoor
   */
  FlexiCombiDoor,
  /* 
  For devices with a fixes substructure
  */
  HasFixedSubstructure,
  /**
   * For Premium Knob (Modular NOL) changes
   */
  PremiumKnob
}

export enum EnergySource {
  Neutral,
  Electro,
  Gas,
  Steam
}

export enum SpecialType {
  BorderAngular,
  BorderRound,
  BorderTrench,
  Hygiene,
  Door
}
