import * as BABYLON from 'babylonjs';
import BlockItem from 'page/Editor/configuration/BlockItem';
import FreeItem from 'page/Editor/configuration/FreeItem';
import Scene from 'page/Editor/Scene';
import { Device, Vector3 } from 'types/Device';
import { Subtype, Type } from 'types/DeviceEnum';
import { isSubtype, toSubtype } from 'utils';
import Dimensions from 'utils/Dimensions';
import HighPerformanceQueue from '../helper/HighPerformanceQueue';
import BasicUtils from '../util/BasicUtils';
import LabelUtils, { Orientation } from '../util/LabelUtils';
import LoaderUtils from '../util/LoaderUtils';
import MarkUtils from '../util/MarkUtils';
import MaterialUtils from '../util/MaterialUtils';
import { OptionNode } from './OptionNode';
import Block from 'page/Editor/configuration/Block';
import CutOut from 'page/Editor/configuration/CutOut';

type Config = {
  model: ConfigModel;
  output?: ConfigOutput;
};

type ConfigModel = {
  node?: BABYLON.TransformNode;
  base?: BABYLON.TransformNode;
  main?: BABYLON.TransformNode;
  device?: Device;
  options: Map<number, OptionNode>;
};

type ConfigOutput = {
  node?: BABYLON.TransformNode;
  mark?: BABYLON.TransformNode;
  markFull?: BABYLON.TransformNode;
  label?: BABYLON.TransformNode;
};

export enum PropertyType {
  /**
   * boolean
   */
  Left,
  /**
   * boolean
   */
  Right,
  /**
   * boolean
   */
  MergeCorpusLeft,
  /**
   * boolean
   */
  MergeCorpusRight,
  /**
   * string
   */
  Border,
  /**
   * string
   */
  Handle,
  /**
   * boolean
   */
  HandleLeft,
  /**
   * boolean
   */
  HandleRight,
  /**
   * String
   */
  Bottom,
  /**
   * Number
   */
  BottomHeight,
  /**
   * Number
   */
  Width,
  /**
   * Number
   */
  Depth,

  /**
   * boolean
   */
  Mark,
  /**
   * boolean
   */
  MarkFull,
  /**
   * boolean
   */
  Label,
  /**
   * boolean
   */
  Rotate,
  /**
   * boolean
   */
  Open,
  /**
   * boolean
   */
  DoubleSidedOperation,
  /**
   * String, for Special Stuff, Arg!
   */
  Special
}

enum ApplyType {
  Base,
  Position,
  Merge,
  Height,
  Width,
  Depth,
  Rotate,
  Handle,
  Special
}

type DeviceNodeDefaultSettings = {
  shadowGenerators?: BABYLON.ShadowGenerator[];
  mirrors?: BABYLON.MirrorTexture[];
  modelNode: BABYLON.TransformNode;
};

export type DeviceNodeSettings = {
  id: string;
  left?: boolean;
  right?: boolean;
  border?: string;
  handle?: string;
  bottom?: 'BaseMKN' | 'Base' | 'Feet';
  bottomHeight?: number;
  width?: number;
  depth?: number;
  device?: Device;
};

export default class DeviceNode extends BABYLON.TransformNode {
  public static defaultSettings: DeviceNodeDefaultSettings = {
    shadowGenerators: [],
    mirrors: [],
    modelNode: null
  };

  private config: Config = {
    model: {
      node: null,
      base: null,
      options: new Map<number, OptionNode>()
    },
    output: {
      node: null,
      mark: null,
      markFull: null,
      label: null
    }
  };

  private deviceId: string;
  private settings = new Map<PropertyType, string | boolean | number>();
  private blockItem: BlockItem;
  private freeItem: FreeItem;

  private _mainSplit = false;

  constructor(device: BlockItem | FreeItem | DeviceNodeSettings) {
    super('Device', Scene.CURRENT_SCENE, undefined);
    this.config.model.node = new BABYLON.TransformNode('model.' + this.uniqueId, Scene.CURRENT_SCENE);
    this.config.model.node.parent = DeviceNode.defaultSettings.modelNode;
    // this.config.model.node.setEnabled(false);
    this.config.output.node = new BABYLON.TransformNode('output', Scene.CURRENT_SCENE);
    this.config.output.node.parent = this;

    this.set(PropertyType.Open, false, false);

    if (device instanceof BlockItem) {
      this.blockItem = device;
      this.config.model.device = device.getDeviceObject();
      this.deviceId = device.getDeviceId();
      this.name = 'Device.' + this.deviceId;
      this.set(PropertyType.Left, false, false);
      this.set(PropertyType.Right, false, false);
      this.set(PropertyType.Handle, device.getBlockRow().getParent().getHandle() ? device.getBlockRow().getParent().getHandle().style : null, false);
      this.set(PropertyType.Bottom, device.getBlockRow().getParent().getBottom(), false);
      this.set(PropertyType.BottomHeight, device.getBlockRow().getParent().getBottomHeight(), false);
      this.set(PropertyType.Width, device.getWidth(), false);
      this.set(PropertyType.Depth, device.getDepth(), false);
      this.set(PropertyType.Label, device.getBlockRow().getParent().isShowLabels(), false);

      if (this.config.model.device.style === 'Dummy') {
        // Anything to do?
      } else {
        // Add Base Corpus to Node
        if (this.config.model.device.model.modularNOL && device.getBlock().getBlockType() === 'ModularNOL')
          LoaderUtils.loadBaseNOL(this, node => {
            node.bake();
          });
        else
          LoaderUtils.loadBase(this, node => {
            node.bake();
          });
        // Load Main Model
        LoaderUtils.loadDevice(
          this,
          () => {
            this.bake();
          },
          () => {
            this.bake();
          }
        );
        // Attach all Components
        this.updateComponents();
      }
    } else if (device instanceof FreeItem) {
      this.freeItem = device;
      this.config.model.device = device.getDeviceObject();
      this.deviceId = device.getDeviceId();
      this.name = 'Device.' + this.deviceId;
      this.set(PropertyType.Left, true, false);
      this.set(PropertyType.Right, true, false);
      this.set(PropertyType.Handle, null, false);
      this.set(PropertyType.Bottom, 'Feet', false);
      this.set(PropertyType.BottomHeight, 15, false);
      this.set(PropertyType.Width, device.getWidth(), false);
      this.set(PropertyType.Depth, device.getDepth(), false);
      this.set(PropertyType.Label, false, false);

      // Add Base Corpus to Node
      LoaderUtils.loadBase(this, node => {
        node.bake();
      });
      // Load Main Model
      LoaderUtils.loadDevice(
        this,
        () => {
          this.bake();
        },
        () => {
          this.bake();
        }
      );
      // Attach all Components
      this.updateComponents();
    } else {
      this.deviceId = device.id;
      this.set(PropertyType.Left, device.left || false, false);
      this.set(PropertyType.Right, device.right || false, false);
      this.set(PropertyType.Border, device.border || 'BorderAngular', false);
      this.set(PropertyType.Handle, device.handle || null, false);
      this.set(PropertyType.Bottom, device.bottom || 'Feet', false);
      this.set(PropertyType.BottomHeight, device.bottomHeight || 20, false);
      this.set(PropertyType.Width, device.width || 600, false);
      this.set(PropertyType.Depth, device.depth || 850, false);

      this.config.model.device = device.device;

      // Add Base Corpus to Node
      LoaderUtils.loadBase(this, node => {
        node.bake();
      });
      // Load Main Model
      LoaderUtils.loadDevice(
        this,
        () => {
          this.bake();
        },
        () => {
          this.bake();
        }
      );
      // Attach all Components
      this.updateComponents();
    }
  }

  public addBase(base: BABYLON.TransformNode): void {
    if (this.config.model.device.model.flexiChef || this.config.model.device.model.spaceCombi) {
      base.dispose();
      return;
    }

    base.parent = this.config.model.node;
    this.config.model.base = base;

    base.getChildMeshes().forEach(mesh => {
      if (mesh.material && this.getBlock()) {
        if (mesh.material.name === '_metal_side') mesh.material = this.getBlock().getDoorColorMaterial();
      }
    });

    this.applySettings(ApplyType.Base);
    this.applySettings(ApplyType.Depth);
    this.applySettings(ApplyType.Width);
    this.applySettings(ApplyType.Height);

    this.applySettings(ApplyType.Handle);
  }

  public setMain(main: BABYLON.TransformNode): void {
    main.parent = this.config.model.node;
    this.config.model.main = main;
    // Apply Height
    if (this.config.model.device.model.flexiChef || this.config.model.device.model.spaceCombi) main.position.y = 0;
    else {
      main.position.y = this.settings.get(PropertyType.BottomHeight) as number;

      if (this.config.model.device.model.modularNOL && this.config.model.device.model.buildBaseCorpus) {
        main.position.y += 5;
      }
    }

    this._mainSplit = false;
    if (BasicUtils.findFirstChild('700', main) || BasicUtils.findFirstChild('850', main)) this._mainSplit = true;

    this.applySettings(ApplyType.Depth);

    // Modular adjustment
    if (
      this.config.model.device &&
      (this.config.model.device.model.modular || this.config.model.device.model.modularNOL) &&
      !this.config.model.device.model.modularBorderIncluded &&
      this.config.model.device.model.buildBaseCorpus
    ) {
      main.scaling.x = (this.config.model.device.model.width - 60) / this.config.model.device.model.width;
      main.position.x = 3;
    }
    this.updateComponents();
  }

  public updateDevice(device: Device) {
    // Set new device
    this.config.model.device = device;
    this.deviceId = device.id;
    this.name = 'Device.' + this.deviceId;
    this.updateMain();
    this.updateComponents();
  }

  public updateComponents() {
    // Clean old components
    const optionsToRemove: OptionNode[] = [];
    this.config.model.options.forEach(v => {
      if (!v.getEquipment()) optionsToRemove.push(v);
    });
    optionsToRemove.forEach(v => this.removeOption(v));
    // Add new ones
    const device = this.config.model.device;
    const toLoad = (device.components ? device.components.length : 0) + 1;
    let loaded = 0;
    // Attach all Components
    if (device.components) {
      for (let i = 0; i < device.components.length; i++) {
        const component = device.components[i];
        LoaderUtils.loadComponent(
          this,
          component,
          () => {
            if (++loaded >= toLoad) this.bake();
          },
          () => {
            if (++loaded >= toLoad) this.bake();
          }
        );
      }
    }
    if (this.config.model.main) {
      if (this.getDeviceObject() && this.getDeviceObject().style.indexOf('C=') >= 0) {
        const test = /C=([\d\w-_]+)(;|$)/;
        let componentStyle = test.exec(this.getDeviceObject().style)[1];
        this.getBlockItem()
          ?.getEquipments()
          ?.forEach(e => {
            const equipmentComponentStyle = test.exec(e.getDeviceObject().style || '')?.[1];
            if (equipmentComponentStyle) componentStyle = equipmentComponentStyle;
          });
        //console.log('componentStyle', componentStyle);
        if (componentStyle) {
          const componentContainer = BasicUtils.findFirstChild(componentStyle, this.config.model.main);
          if (componentContainer) {
            let loaded = 0;
            const toLoad = componentContainer.getChildMeshes().length;
            componentContainer.getChildMeshes().forEach(m => {
              const id = m.name.match(/#([\d\w]+)/)[1];
              LoaderUtils.loadComponent(
                this,
                {
                  component: {
                    id,
                    name: id
                  },
                  position: m.position.add(this.getDeviceObject().model.modularNOL ? new BABYLON.Vector3(0, 5, 0) : BABYLON.Vector3.Zero()),
                  scaling: m.scaling,
                  backside: false,
                  fixed: true
                },
                () => {
                  if (++loaded >= toLoad) this.bake();
                },
                () => {
                  if (++loaded >= toLoad) this.bake();
                }
              );
            });
          }
        }
      }
    }
  }

  private updateMain() {
    if (this.config.model.main) this.config.model.main.dispose();
    this.config.model.main = null;
    LoaderUtils.loadDevice(
      this,
      () => {
        this.bake();
      },
      () => {
        this.bake();
      }
    );
  }

  public addOption(option: OptionNode, bake?: boolean): OptionNode {
    // Set Parent
    option.parent = this.config.model.node;
    // Apply Position
    if (option.getDeviceComponent().position)
      option.getModel().position = new BABYLON.Vector3(
        option.getDeviceComponent().position.x,
        option.getDeviceComponent().position.y,
        option.getDeviceComponent().position.z
      );
    if (option.getDeviceComponent().scaling)
      option.getModel().scaling = new BABYLON.Vector3(
        option.getDeviceComponent().scaling.x,
        option.getDeviceComponent().scaling.y,
        option.getDeviceComponent().scaling.z
      );
    // Prepare Type
    option.setContainer(this);
    option.prepareType();
    // Apply Height
    if (!this.config.model.device.model.spaceCombi) {
      option.position.y = this.settings.get(PropertyType.BottomHeight) as number;
    }
    // Apply Depth
    if (!option.getDeviceComponent().fixed) {
      switch (this.settings.get(PropertyType.Depth) as number) {
        case 700:
          option.position.z = 15;
          break;
        case 850:
          option.position.z = 0;
          break;
      }
    }
    // Add to Map
    this.config.model.options.set(option.uniqueId, option);

    if (bake) this.bake();

    // Special for Upper Drawer
    if (option.getDeviceComponent().component.id === 'd2nB2') {
      if (this.blockItem) {
        if (this.blockItem.prev()) this.blockItem.prev().getNode().bake();
        if (this.blockItem.next()) this.blockItem.next().getNode().bake();
      }
    }

    return option;
  }

  public removeOption(option: BABYLON.TransformNode | string | number): void {
    if (option instanceof BABYLON.TransformNode) {
      // Node
      this.config.model.options.delete(option.uniqueId);
      if (!option.isDisposed()) option.dispose();
    } else if (typeof option === 'number') {
      // Number
      const obj = this.config.model.options.get(option);
      if (obj) {
        this.config.model.options.delete(option);
        if (!obj.isDisposed()) obj.dispose();
      }
    } else {
      // Name
      let obj: BABYLON.TransformNode;
      this.config.model.options.forEach(element => {
        if (element.name === (option as string)) {
          obj = element;
        }
      });
      if (obj) {
        this.config.model.options.delete(obj.uniqueId);
        if (!obj.isDisposed()) obj.dispose();
      }
    }
  }

  public containsOption(componentId: string) {
    const options = this.config.model.options.values();
    let option = null;
    while (typeof (option = options.next().value) !== 'undefined') {
      if (option.getDeviceComponent().component.id === componentId) return true;
    }
    return false;
  }

  private applySettings(type: ApplyType) {
    const base = this.config.model.base;

    // ---
    const masterline = this.getBlock()?.getBlockType() === 'Masterline';
    const modular = this.getBlock()?.getBlockType() === 'Modular';
    const modularNOL = this.getBlock()?.getBlockType() === 'ModularNOL';
    const marine = this.getBlock()?.getBlockType() === 'MarineMeister';

    switch (type) {
      case ApplyType.Base:
        {
          // this.applySettings(ApplyType.Rotate);
          if (!base) return;

          // Top Part
          // Border Settings
          const borders = [
            'BorderAngular',
            'BorderAngular_chamfer',
            'BorderAngular_upturn',
            'BorderAngular_weld',
            'BorderRound',
            'BorderTrench',
            'BorderTrench_NOL_sink',
            'Border45',
            'BorderRoundRound',
            'BorderRoundRound5cm'
          ];
          for (let i = 0; i < borders.length; i++) {
            const border = borders[i];
            const node = BasicUtils.findFirstChild(border, base);
            if (node) {
              const left = BasicUtils.findFirstChild('Left', node);
              const right = BasicUtils.clone(left, 'Right');
              right.scaling.x = -1;
            }
          }
          // Handle Settings
          const backOffset = -80;
          const handles = ['Handle20x40', 'Handle40x80', 'HandleRound'];
          for (let i = 0; i < handles.length; i++) {
            const handle = handles[i];
            const node = BasicUtils.findFirstChild(handle, base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
            // - Clone everything for back usage
            const clone = BasicUtils.clone(node, handle + '_back');
            clone.scaling.z = -1;
            clone.position.z = backOffset;
            clone.getChildTransformNodes().forEach(child => {
              if (child.name === 'Flex') {
                child.name = 'NoFlex';
                child.position.z = 0;
              }
            });
          }
          {
            const node = BasicUtils.findFirstChild('HandleRound_side', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          // HandleMount Settings
          {
            const node = BasicUtils.findFirstChild('HandleMount', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
            // - Clone everything for back usage
            const clone = BasicUtils.clone(node, 'HandleMount_back');
            clone.scaling.z = -1;
            clone.position.z = backOffset;
            clone.getChildTransformNodes().forEach(child => {
              if (child.name === 'Flex') {
                child.name = 'NoFlex';
                child.position.z = 0;
              }
            });
          }
          // Corpus Settings
          {
            BasicUtils.findChildren('Corpus', base).forEach(node => {
              const left = BasicUtils.findFirstChild('Left', node);
              const right = BasicUtils.clone(left, 'Right');
              right.scaling.x = -1;

              // Open Corpus
              {
                const node2 = BasicUtils.clone(node, 'CorpusOpen');
                let disposal;
                if ((disposal = BasicUtils.findFirstChild('Center:Shared:B', node2))) disposal.dispose();
                if ((disposal = BasicUtils.findFirstChild('Left:Shared:BLB', node2))) disposal.dispose();
                if ((disposal = BasicUtils.findFirstChild('Right:Shared:BLB', node2))) disposal.dispose();
              }

              // Corpus LWT
              if (node.parent?.name === 'DownUnder') {
                const node2 = BasicUtils.clone(node, 'CorpusLWT');
                let disposal;
                if ((disposal = BasicUtils.findFirstChild('Left', node2))) disposal.dispose();
                if ((disposal = BasicUtils.findFirstChild('Right', node2))) disposal.dispose();
              }
            });
          }
          // Corpus Backside Color Settings
          {
            const node = BasicUtils.findFirstChild('Corpus_backCover', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          // CorpusSideCover Settings
          {
            BasicUtils.findChildren('CorpusSideCover', base).forEach(node => {
              const left = BasicUtils.findFirstChild('Left', node);
              const right = BasicUtils.clone(left, 'Right');
              right.scaling.x = -1;

              let edge: BABYLON.TransformNode = null;

              if ((edge = BasicUtils.findFirstChild('Edge', left))) {
                const leftSingleFree = new BABYLON.TransformNode('LeftSingleFree', base.getScene());
                leftSingleFree.parent = node;
                BasicUtils.clone(edge, 'Edge', leftSingleFree);
                let flex = BasicUtils.findFirstChild('Edge:Flex', leftSingleFree);
                flex.name = 'NoFlex';
                flex.position.z = 0;
                leftSingleFree.position.z = backOffset;
                leftSingleFree.scaling.z = -1;
              }

              if ((edge = BasicUtils.findFirstChild('Edge', right))) {
                const rightSingleFree = new BABYLON.TransformNode('RightSingleFree', base.getScene());
                rightSingleFree.parent = node;
                BasicUtils.clone(edge, 'Edge', rightSingleFree);
                let flex = BasicUtils.findFirstChild('Edge:Flex', rightSingleFree);
                flex.name = 'NoFlex';
                flex.position.z = 0;
                rightSingleFree.position.z = backOffset;
                rightSingleFree.scaling.x = -1;
                rightSingleFree.scaling.z = -1;
              }
            });
          }
          // Panel Settings
          {
            const node = BasicUtils.findFirstChild('Panel', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;

            const backPanel = BasicUtils.clone(node, 'PanelBack');
            backPanel.scaling.z = -1;
          }
          // UpturnBack Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          // UpturnBack NOL Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack_NOL', base);
            if (node) {
              const left = BasicUtils.findFirstChild('Left', node);
              const right = BasicUtils.clone(left, 'Right');
              right.scaling.x = -1;
            }
          }
          // UpturnBack NOL Sink Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack_NOL_sink', base);
            if (node) {
              const left = BasicUtils.findFirstChild('Left', node);
              const right = BasicUtils.clone(left, 'Right');
              right.scaling.x = -1;
            }
          }

          // Bottom Part
          // PedestrialCover Settings
          {
            const node = BasicUtils.findFirstChild('PedestrialCover', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }
          // Feet Settings
          {
            const node = BasicUtils.findFirstChild('Feet', base);
            const left = BasicUtils.findFirstChild('Left', node);
            const right = BasicUtils.clone(left, 'Right');
            right.scaling.x = -1;
          }

          if (modularNOL) {
            BasicUtils.findFirstChild('Top:Basic', base)!.scaling!.y = 1.12;
            BasicUtils.findFirstChild('Top:DownUnder', base)!.position!.y = 5;
            BasicUtils.findFirstChild('Top:TopOver', base)!.position!.y = 5;
          }

          // this.applySettings(ApplyType.Position);
          // this.applySettings(ApplyType.Merge);
          this.applySettings(ApplyType.Special);
        }
        break;
      case ApplyType.Position:
        {
          if (!base) return;
          const targetWidth = this.settings.get(PropertyType.Width) as number;
          // mm to cm
          const width = Dimensions.CM(targetWidth);
          // cm to scaling
          const scaling = width / 10;

          let left = this.settings.get(PropertyType.Left);
          let right = this.settings.get(PropertyType.Right);

          // Modular Special
          if (modular || modularNOL) {
            left = true;
            right = true;
          }

          // Panel Settings
          {
            const node = BasicUtils.findFirstChild('Panel', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (left && right) {
              center.scaling.x = Math.max(0, scaling - 2);
              center.position.x = 10;
            } else if (left) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            } else if (right) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            }
          }
          {
            const node = BasicUtils.findFirstChild('PanelBack', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (left && right) {
              center.scaling.x = Math.max(0, scaling - 2);
              center.position.x = 10;
            } else if (left) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            } else if (right) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            }
          }

          // Bottom Part
          // PedestrialCover Settings
          {
            const node = BasicUtils.findFirstChild('PedestrialCover', base);
            const center = BasicUtils.findFirstChild('Center', node);
            if (left && right) {
              center.scaling.x = Math.max(0, scaling - 2);
              center.position.x = 10;
            } else if (left) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            } else if (right) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            }
          }
        }
        break;

      case ApplyType.Merge:
        {
          if (!base) return;
          const targetWidth = this.settings.get(PropertyType.Width) as number;
          // mm to cm
          const width = Dimensions.CM(targetWidth);
          // cm to scaling
          const scaling = width / 10;

          const mergeCorpusLeft = this.settings.get(PropertyType.MergeCorpusLeft);
          const mergeCorpusRight = this.settings.get(PropertyType.MergeCorpusRight);
          // Corpus Settings
          const handleCorpus = (node: BABYLON.TransformNode) => {
            const center = BasicUtils.findFirstChild('Center', node);
            if (mergeCorpusLeft && mergeCorpusRight) {
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            } else if (mergeCorpusLeft) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 0;
            } else if (mergeCorpusRight) {
              center.scaling.x = Math.max(0, scaling - 1);
              center.position.x = 10;
            }
          };
          BasicUtils.findChildren('Corpus', base).forEach(node => handleCorpus(node));
          BasicUtils.findChildren('CorpusOpen', base).forEach(node => handleCorpus(node));
        }
        break;

      case ApplyType.Height:
        {
          const height = this.settings.get(PropertyType.BottomHeight) as number;

          if (base) {
            {
              const top = BasicUtils.findFirstChild('Top', base);
              const bottom = BasicUtils.findFirstChild('Bottom', base);
              top.position.y = height;
              bottom.scaling.y = height / 20;
            }

            // Main
            if (this.config.model.main) {
              this.config.model.main.position.y = height;
              if (modularNOL) this.config.model.main.position.y += 5;
            }
          }

          // All options
          const options = this.config.model.options.values();
          let option = null;
          while (typeof (option = options.next().value) !== 'undefined') {
            option.position.y = height;
          }
        }
        break;

      case ApplyType.Depth:
        this.applySettings(ApplyType.Rotate);
        if (!base) return;
        const targetDepth = this.settings.get(PropertyType.Depth) as number;
        // mm to cm
        const depth = Dimensions.CM(targetDepth);
        // Panel settings
        {
          const backPanel = BasicUtils.findFirstChild('PanelBack', base);
          if (backPanel) backPanel.position.z = -depth + 5.2;
        }
        switch (targetDepth) {
          case 700:
            {
              const children = base.getChildTransformNodes();
              for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (child.name === 'Flex') {
                  child.position.z = 15;
                }
              }

              // All options
              const options = this.config.model.options.values();
              let option = null;
              while (typeof (option = options.next().value) !== 'undefined') {
                if (option instanceof OptionNode) {
                  if (!option.getDeviceComponent().fixed) option.position.z = 15;
                  option.prepareTypeDepthChange();
                }
              }
              // Move Main
              if (this.config.model.main) this.config.model.main.position.z = 0;
            }
            break;
          case 850:
            {
              const children = base.getChildTransformNodes();
              for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (child.name === 'Flex') {
                  child.position.z = 0;
                }
              }

              // All options
              const options = this.config.model.options.values();
              let option = null;
              while (typeof (option = options.next().value) !== 'undefined') {
                if (option instanceof OptionNode) {
                  if (!option.getDeviceComponent().fixed) option.position.z = 0;
                  option.prepareTypeDepthChange();
                }
              }

              // Move Main & Cover Up
              if (this.config.model.device && this.config.model.device.model.extendCover) {
                const extendCoverModelFront = BasicUtils.findFirstChild('CoverSplit:Front', this.config.model.base);
                const extendCoverModelBack = BasicUtils.findFirstChild('CoverSplit:Back', this.config.model.base);
                const extendCoverSize = this.config.model.device.model.extendCoverSize;
                const scaleFront = Math.max(0, Math.min(15, Dimensions.CM(extendCoverSize) / 10));
                const scaleBack = Math.max(0, Math.min(15, Dimensions.CM(150 - extendCoverSize) / 10));
                extendCoverModelFront.getChildMeshes().forEach(mesh => {
                  mesh.scaling.z = scaleFront;
                });
                extendCoverModelBack.getChildMeshes().forEach(mesh => {
                  mesh.scaling.z = scaleBack;
                });
                if (this.config.model.main) this.config.model.main.position.z = -15 + Dimensions.CM(extendCoverSize);
              } else {
                if (this.config.model.main) this.config.model.main.position.z = 0;
              }
            }
            break;
        }
        break;

      case ApplyType.Width:
        {
          this.applySettings(ApplyType.Rotate);
          if (!base) return;
          const targetWidth = this.settings.get(PropertyType.Width) as number;
          const targetDepth = this.settings.get(PropertyType.Depth) as number;
          // mm to cm
          const width = Dimensions.CM(targetWidth);
          const depth = Dimensions.CM(targetDepth);
          // cm to scaling
          const scaling = width / 10;

          // Top Part
          // Border Settings
          const borders = [
            'BorderAngular',
            'BorderAngular_chamfer',
            'BorderAngular_upturn',
            'BorderAngular_weld',
            'BorderRound',
            'BorderTrench',
            'BorderTrench_NOL_sink',
            'Border45',
            'BorderRoundRound',
            'BorderRoundRound5cm'
          ];
          for (let i = 0; i < borders.length; i++) {
            const border = borders[i];
            const node = BasicUtils.findFirstChild(border, base);
            if (node) {
              if (border.startsWith('BorderTrench')) BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 0.6);
              else BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
              BasicUtils.findFirstChild('Right', node).position.x = width;
            }
          }
          // Handle Settings
          const handles = ['Handle20x40', 'Handle40x80', 'HandleRound'];
          for (let i = 0; i < handles.length; i++) {
            const handle = handles[i];
            const node = BasicUtils.findFirstChild(handle, base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
            BasicUtils.findFirstChild('Right', node).position.x = width;
            const node2 = BasicUtils.findFirstChild(handle + '_back', base);
            BasicUtils.findFirstChild('Center', node2).scaling.x = Math.max(0, scaling);
            BasicUtils.findFirstChild('Right', node2).position.x = width;
          }
          {
            const node = BasicUtils.findFirstChild('HandleRound_side', base);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          // HandleMount Settings
          {
            const node = BasicUtils.findFirstChild('HandleMount', base);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          {
            const node = BasicUtils.findFirstChild('HandleMount_back', base);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          // Corpus Settings
          {
            BasicUtils.findChildren('Corpus', base).forEach(node => {
              BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
              BasicUtils.findFirstChild('Right', node).position.x = width;
            });
            BasicUtils.findChildren('CorpusOpen', base).forEach(node => {
              BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
              BasicUtils.findFirstChild('Right', node).position.x = width;
            });
            BasicUtils.findChildren('CorpusLWT', base).forEach(node => {
              const center = BasicUtils.findFirstChild('Center', node);
              center.scaling.x = Math.max(0, scaling);
              center.position.x = 0;
            });
          }
          // Corpus Backside Color Settings
          {
            const node = BasicUtils.findFirstChild('Corpus_backCover', base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          // CorpusSideCover Settings
          {
            BasicUtils.findChildren('CorpusSideCover', base).forEach(node => {
              const right = BasicUtils.findFirstChild('Right', node);
              right.position.x = width;

              let rightSingleFree: BABYLON.TransformNode = null;
              if ((rightSingleFree = BasicUtils.findFirstChild('RightSingleFree', node))) {
                rightSingleFree.position.x = width;
              }
            });
          }
          // Cover Settings
          {
            const node = BasicUtils.findFirstChild('Cover', base);
            node.scaling.x = scaling;

            // Modular adjustment
            if (modular || modularNOL) {
              node.scaling.x = scaling - 0.6;
              node.position.x = 3;
            }
          }
          // CoverSplit Settings
          {
            const node = BasicUtils.findFirstChild('CoverSplit', base);
            node.scaling.x = scaling;

            // Modular adjustment
            if (modular || modularNOL) {
              node.scaling.x = scaling - 0.6;
              node.position.x = 3;
            }
          }
          // Panel Settings
          {
            const node = BasicUtils.findFirstChild('Panel', base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', node).position.x = width;

            const backPanel = BasicUtils.findFirstChild('PanelBack', base);
            if (backPanel) {
              BasicUtils.findFirstChild('Center', backPanel).scaling.x = Math.max(0, scaling - 2);
              BasicUtils.findFirstChild('Right', backPanel).position.x = width;
              backPanel.position.z = -depth + 5.2;
            }
          }
          // UpturnBack Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack', base);
            BasicUtils.findFirstChild('Right', node).position.x = width;
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
          }
          // UpturnBack NOL Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack_NOL', base);
            if (node) {
              BasicUtils.findFirstChild('Right', node).position.x = width;
              BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
            }
          }
          // UpturnBack NOL Sink Settings
          {
            const node = BasicUtils.findFirstChild('UpturnBack_NOL_sink', base);
            if (node) {
              BasicUtils.findFirstChild('Right', node).position.x = width;
              BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling);
            }
          }

          // Bottom Part
          // PedestrialCover Settings
          {
            const node = BasicUtils.findFirstChild('PedestrialCover', base);
            BasicUtils.findFirstChild('Center', node).scaling.x = Math.max(0, scaling - 2);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }
          // Feet Settings
          {
            const node = BasicUtils.findFirstChild('Feet', base);
            BasicUtils.findFirstChild('Right', node).position.x = width;
          }

          this.config.model.options.forEach(optionNode => optionNode.prepareTypeWidthChange());

          this.applySettings(ApplyType.Position);
          this.applySettings(ApplyType.Merge);
        }
        break;

      case ApplyType.Rotate:
        if (this.settings.get(PropertyType.Rotate)) {
          this.config.output.node.position = new BABYLON.Vector3(
            (this.get(PropertyType.Width) as number) / 10,
            0,
            -(this.get(PropertyType.Depth) as number) / 10
          );
          this.config.output.node.rotation = new BABYLON.Vector3(0, Math.PI, 0);
        } else {
          this.config.output.node.position = BABYLON.Vector3.Zero();
          this.config.output.node.rotation = BABYLON.Vector3.Zero();
        }
        break;

      case ApplyType.Handle:
        let handle;
        if (this.config.model.base && (handle = this.settings.get(PropertyType.Handle)) && typeof handle === 'string') {
          let handleSplitIndex = -1;
          if ((handleSplitIndex = handle.indexOf(';')) > 0) {
            const handleSplit = handle.split(';');
            const handleName = handle.substring(0, handleSplitIndex);
            for (let i = 1; i < handleSplit.length; i++) {
              let element = handleSplit[i];
              if (element.startsWith('d')) {
                element = element.substring(1);
                const number = Number.parseFloat(element);
                const scale = (3.5 / number) * 3.5;
                const move = number - 3.5;

                const handleMount = BasicUtils.findFirstChild('HandleMount', this.config.model.base);
                handleMount.getChildMeshes().forEach(mesh => {
                  mesh.scaling.z = scale;
                });
                BasicUtils.findFirstChild(handleName, this.config.model.base).position.z = -move;
              }
            }
          } else {
            const handleMount = BasicUtils.findFirstChild('HandleMount', this.config.model.base);
            handleMount.getChildMeshes().forEach(mesh => {
              mesh.scaling.z = 1;
            });
            BasicUtils.findFirstChild(handle, this.config.model.base).position.z = 0;
          }
        }
        break;

      case ApplyType.Special:
        if (this.config.model.base) {
          const topOver = BasicUtils.findFirstChild('Top:TopOver', this.config.model.base);
          const downUnder = BasicUtils.findFirstChild('Top:DownUnder', this.config.model.base);
          const basic = BasicUtils.findFirstChild('Top:Basic', this.config.model.base);
          const bottom = BasicUtils.findFirstChild('Bottom', this.config.model.base);
          switch (this.settings.get(PropertyType.Special)) {
            case 'Lowered':
              topOver.position.y = -22;
              downUnder.getChildMeshes().forEach(m => (m.isPickable = false));
              break;
            case 'CoverOnly':
              downUnder.getChildMeshes().forEach(m => (m.isPickable = false));
              basic.getChildMeshes().forEach(m => (m.isPickable = false));
              bottom.getChildMeshes().forEach(m => (m.isPickable = false));
              break;

            default:
              topOver.position.y = modularNOL ? 5 : 0;
              downUnder.getChildMeshes().forEach(m => (m.isPickable = true));
              basic.getChildMeshes().forEach(m => (m.isPickable = true));
              bottom.getChildMeshes().forEach(m => (m.isPickable = true));
              break;
          }
        }
        break;
    }
  }

  public set(property: PropertyType, value: string | boolean | number, bake?: boolean) {
    this.settings.set(property, value);
    switch (property) {
      case PropertyType.Left:
      case PropertyType.Right:
        this.applySettings(ApplyType.Position);
        break;
      case PropertyType.MergeCorpusLeft:
      case PropertyType.MergeCorpusRight:
        this.applySettings(ApplyType.Merge);
        break;
      case PropertyType.BottomHeight:
        this.applySettings(ApplyType.Height);
        break;
      case PropertyType.Width:
        this.applySettings(ApplyType.Width);
        break;
      case PropertyType.Depth:
        this.applySettings(ApplyType.Depth);
        break;

      case PropertyType.Handle:
        if (value) {
          if (this.getBlockItem() && !this.getBlockItem().compatibleWithEquipment(Subtype.HandRail)) this.settings.set(property, false);
          this.applySettings(ApplyType.Handle);
        }
        break;

      case PropertyType.Mark:
        if (this.config.output.mark && !this.config.output.mark.isDisposed()) this.config.output.mark.setEnabled(value as boolean);
        break;
      case PropertyType.MarkFull:
        if (this.config.output.markFull && !this.config.output.markFull.isDisposed()) this.config.output.markFull.setEnabled(value as boolean);
        break;
      case PropertyType.Label:
        if (this.config.output.label && !this.config.output.label.isDisposed()) this.config.output.label.setEnabled(value as boolean);
        break;

      case PropertyType.Rotate:
        this.applySettings(ApplyType.Rotate);
        break;

      case PropertyType.Special:
        this.applySettings(ApplyType.Special);
        break;

      default:
        break;
    }

    if (bake) this.bake();
  }

  public get(property: PropertyType): string | boolean | number {
    return this.settings.get(property);
  }

  public getDeviceId() {
    return this.deviceId;
  }
  public getDeviceObject() {
    return this.blockItem ? this.blockItem.getDeviceObject() : this.freeItem ? this.freeItem.getDeviceObject() : null;
  }

  public getBlockItem() {
    return this.blockItem;
  }

  public getFreeItem() {
    return this.freeItem;
  }

  public getBlockRow() {
    if (!this.blockItem) return null;
    return this.blockItem.getBlockRow();
  }

  public getBlock() {
    if (!this.blockItem) return null;
    return this.blockItem.getBlock();
  }

  public setPosition(position: { x?: number; y?: number; z?: number }) {
    // console.log('setPosition', position)
    if (typeof position.x !== 'undefined') {
      this.position.x = position.x;
      if (this.blockItem) this.blockItem.getPosition().x = position.x;
      if (this.freeItem) this.freeItem.getPosition().x = position.x;
    }
    if (typeof position.y !== 'undefined') {
      this.position.y = position.y;
      if (this.blockItem) this.blockItem.getPosition().y = position.y;
      if (this.freeItem) this.freeItem.getPosition().y = position.y;
    }
    if (typeof position.z !== 'undefined') {
      this.position.z = position.z;
      if (this.blockItem) this.blockItem.getPosition().z = position.z;
      if (this.freeItem) this.freeItem.getPosition().z = position.z;
    }
  }

  public getBounds() {
    return BasicUtils.getBounds(this.config.output.node);
  }

  public getOutputNode() {
    return this.config?.output?.node;
  }

  public bake(): void {
    // console.log('bake()', this);
    HighPerformanceQueue.push(this.uniqueId, () => {
      // Clear old output
      {
        const children = this.config.output.node.getChildren();
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (child instanceof BABYLON.AbstractMesh) {
            for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
              const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
              shadowGenerator.removeShadowCaster(child);
            }
          }
          child.dispose();
        }
      }
      let width = this.settings.get(PropertyType.Width) as number;
      let depth = this.settings.get(PropertyType.Depth) as number;
      let height = 0;
      if (this.config.model.device.style === 'Dummy') {
        width = this.getBlockItem().getWidth();
        depth = this.getBlockRow().getDepth();
        const block = BABYLON.MeshBuilder.CreateBox('box', { size: 1, width: 1, height: 1, depth: 1 }, this.getScene());
        block.material = this.getScene().getMaterialByName('mark:full');
        block.parent = this.getOutputNode();
        block.scaling.x = Dimensions.CM(this.getBlockItem().getWidth());
        block.scaling.y = Dimensions.CM(this.getBlockItem().getHeight());
        block.scaling.z = Dimensions.CM(this.getBlockItem().getDepth());
        block.position.x = block.scaling.x / 2;
        block.position.y = block.scaling.y / 2;
        block.position.z = -block.scaling.z / 2;
      } else {
        // Build new Merge
        let left = this.settings.get(PropertyType.Left) as boolean;
        let right = this.settings.get(PropertyType.Right) as boolean;
        const mergeCorpusLeft = this.settings.get(PropertyType.MergeCorpusLeft);
        const mergeCorpusRight = this.settings.get(PropertyType.MergeCorpusRight);
        let borderLeft = 'BorderTrench';
        let borderRight = 'BorderTrench';
        let single = true;
        let singleType = null;
        let sideCover = false;
        let extendedSize = 0;
        let handleBack = false;
        let masterline = false;
        let modular = false;
        let modularNOL = false;
        let marine = false;
        let blockItem = false;
        let spaceCombi = false;
        let spaceCombiDoorR = false;
        let flexiChef = false;
        let spaceClean = true;
        let cutOut: CutOut | null = null;
        // Handle
        let handleType = 'Both';
        let upperstructureOnly = false;

        let upturnBack = 'UpturnBack';

        let lwt = false; // Lowered Work Table
        if (this.getBlockItem()) {
          blockItem = true;
          single = this.getBlockItem().getBlockRow().getParent().getType() === 'Single';
          singleType = this.getBlockItem().getBlockRow().getParent().getSingleType();
          sideCover =
            (!single || (single && singleType === 'Free')) &&
            this.getBlock().isSideCover() &&
            (this.getBlockRow().getType() === 'Bottom'
              ? (left && !this.getBlock().getSideBoardLeftId()) || (right && !this.getBlock().getSideBoardRightId())
              : (right && !this.getBlock().getSideBoardLeftId()) || (left && !this.getBlock().getSideBoardRightId()));
          extendedSize = this.getBlockItem().getBlockRow().getExtendedSize();
          borderLeft = this.getBlockItem().getBlockRow().getParent().getBorderLeftStyle();
          borderRight = this.getBlockItem().getBlockRow().getParent().getBorderRightStyle();
          // ---
          masterline = this.getBlock().getBlockType() === 'Masterline';
          modular = this.getBlock().getBlockType() === 'Modular';
          modularNOL = this.getBlock().getBlockType() === 'ModularNOL' && this.getDeviceObject().model.modularNOL;
          marine = this.getBlock().getBlockType() === 'MarineMeister';
          // ---
          height = this.getBlockItem()._device.model.height;

          upperstructureOnly = this.getBlock().getUpperstrcutureOnly() ?? false;

          lwt = this.getDeviceObject().style.indexOf('LWT') >= 0;

          let top = false;
          if (this.getBlockItem().getBlockRow().getType() === 'Top') {
            const tmp = borderLeft;
            borderLeft = borderRight;
            borderRight = tmp;
            top = true;
          }
          if (modular || modularNOL) {
            left = true;
            right = true;
          }
          if ((masterline || marine) && single && singleType === 'Free') {
            handleBack = true;
          }
          if (masterline && this.getBlockItem().getCutOut()) {
            cutOut = this.getBlockItem().getCutOut();
          }
          if (modularNOL) {
            upturnBack = 'UpturnBack_NOL';
            if (this.config.model.device.model.modularSink) {
              upturnBack += '_sink';
              borderLeft += '_NOL_sink';
              borderRight += '_NOL_sink';
            }
          }
          handleType = this.getBlock()?.getHandleType();
          if (marine && this.config.model.base) {
            const upturn = BasicUtils.findFirstChild(upturnBack, this.config.model.base);
            BasicUtils.findChildren('B1', upturn).forEach(n => (n.scaling.y = this.getBlock().getUpturn() / 25));
            BasicUtils.findChildren('BL1', upturn).forEach(n => (n.scaling.y = this.getBlock().getUpturn() / 25));

            if (borderLeft === 'BorderAngular_upturn') {
              const upturnL = BasicUtils.findFirstChild('BorderAngular_upturn:Left', this.config.model.base);
              BasicUtils.findChildren('L1', upturnL).forEach(n => (n.scaling.y = (!top ? this.getBlock().getUpturnL() : this.getBlock().getUpturnR()) / 25));
              BasicUtils.findChildren('FL1', upturnL).forEach(n => (n.scaling.y = (!top ? this.getBlock().getUpturnL() : this.getBlock().getUpturnR()) / 25));
              BasicUtils.findChildren('BL1', upturnL).forEach(n => (n.scaling.y = (!top ? this.getBlock().getUpturnL() : this.getBlock().getUpturnR()) / 25));
            }

            if (borderRight === 'BorderAngular_upturn') {
              const upturnR = BasicUtils.findFirstChild('BorderAngular_upturn:Right', this.config.model.base);
              BasicUtils.findChildren('L1', upturnR).forEach(n => (n.scaling.y = (!top ? this.getBlock().getUpturnR() : this.getBlock().getUpturnL()) / 25));
              BasicUtils.findChildren('FL1', upturnR).forEach(n => (n.scaling.y = (!top ? this.getBlock().getUpturnR() : this.getBlock().getUpturnL()) / 25));
              BasicUtils.findChildren('BL1', upturnR).forEach(n => (n.scaling.y = (!top ? this.getBlock().getUpturnR() : this.getBlock().getUpturnL()) / 25));
            }
          }
          if (lwt) {
            BasicUtils.findFirstChild('TopOver', this.config.model.base).position.y = -22;
            BasicUtils.findFirstChild('TopOver:UpturnBack', this.config.model.base).position.y = 22;
          }

          if (this.config.model.main) {
            const upperRotation = BasicUtils.findFirstChild('upperRotation', this.config.model.main);
            if (upperRotation) {
              upperRotation.rotation = this.getBlockItem().getUpperRotation().get();
            }
          }
        } else {
          borderLeft = this.settings.get(PropertyType.Border) as string;
          borderRight = this.settings.get(PropertyType.Border) as string;
        }
        if (this.freeItem) {
          if (this.freeItem.getDeviceObject().model.flexiChef) {
            flexiChef = true;
            this.freeItem.getEquipments().forEach(e => {
              if (isSubtype(e.getDeviceObject().subtype, [Subtype.SpaceClean])) spaceClean = false;
            });
          }
          if (this.freeItem.getDeviceObject().model.spaceCombi) {
            spaceCombi = true;
            this.freeItem.getEquipments().forEach(e => {
              if (isSubtype(e.getDeviceObject().subtype, [Subtype.FlexiCombiDoor])) spaceCombiDoorR = true;
            });
          }
        }
        let handle = this.settings.get(PropertyType.Handle);
        let handleSplitIndex = -1;
        if (handle && (handleSplitIndex = handle.toString().indexOf(';')) > 0) {
          handle = handle.toString().substring(0, handleSplitIndex);
        }
        const bottom = this.settings.get(PropertyType.Bottom);

        let underframeOffset = 0;

        if (this.config.model.base) {
          if (this.config.model.device && this.config.model.device.model.buildBaseCorpus) {
            const leftExtend = left && Block.isBorderOverflow(borderLeft);
            const rightExtend = right && Block.isBorderOverflow(borderRight);
            if ((leftExtend || rightExtend) && single && singleType === 'Wall') {
              // +~5 cm
              const w = Dimensions.CM(width);
              const scaling = w / 10 + (leftExtend ? 0.5 : 0) + (rightExtend ? 0.5 : 0);
              const node = BasicUtils.findFirstChild(upturnBack, this.config.model.base);
              const leftNode = BasicUtils.findFirstChild('Left', node);
              leftNode.position.x = leftExtend ? -4.8 : 0; // 4.8 insted of 5 because of a 2mm overflow at upturn back
              const rightNode = BasicUtils.findFirstChild('Right', node);
              rightNode.position.x = w + (rightExtend ? 4.8 : 0); // 4.8 insted of 5 because of a 2mm overflow at upturn back
              const centerNode = BasicUtils.findFirstChild('Center', node);
              centerNode.scaling.x = Math.max(0, scaling);
              centerNode.position.x = leftNode.position.x;
              if (lwt) {
                // TODO
                BasicUtils.findChildren('CorpusLWT', this.config.model.base).forEach(node => {
                  const center = BasicUtils.findFirstChild('Center', node);
                  center.scaling.x = Math.max(0, scaling);
                  if (leftExtend) center.position.x = -5;
                  else center.position.x = 0;
                });
              }
            } else {
              // +0 cm
              const w = Dimensions.CM(width);
              const scaling = w / 10;
              const node = BasicUtils.findFirstChild(upturnBack, this.config.model.base);
              if (node) {
                const leftNode = BasicUtils.findFirstChild('Left', node);
                if (leftNode) leftNode.position.x = 0;
                const rightNode = BasicUtils.findFirstChild('Right', node);
                if (rightNode) rightNode.position.x = w;
                const centerNode = BasicUtils.findFirstChild('Center', node);
                if (centerNode) {
                  centerNode.scaling.x = Math.max(0, scaling);
                  centerNode.position.x = leftNode.position.x;
                }
              }
              if (lwt) {
                // TODO
                BasicUtils.findChildren('CorpusLWT', this.config.model.base).forEach(node => {
                  const center = BasicUtils.findFirstChild('Center', node);
                  center.scaling.x = Math.max(0, scaling);
                  center.position.x = 0;
                });
              }
            }

            // Change Upturn Position based on DepthExtension
            BasicUtils.findFirstChild(upturnBack, this.config.model.base).position.z = this.getBlock().getDepthExtension() / 10;

            if (handle) {
              const handleLeft = BasicUtils.findFirstChild(handle + ':Left', this.config.model.base);
              if (handleLeft != null) {
                if (leftExtend) handleLeft.position.x = -5;
                else handleLeft.position.x = 0;
              }
              const handleRight = BasicUtils.findFirstChild(handle + ':Right', this.config.model.base);
              if (handleRight != null) {
                if (rightExtend) handleRight.position.x = 5 + Dimensions.CM(width);
                else handleRight.position.x = Dimensions.CM(width);
              }
              // Dark Side of Corpus
              if (handleBack) {
                const handleLeft = BasicUtils.findFirstChild(handle + '_back:Left', this.config.model.base);
                if (handleLeft != null) {
                  if (leftExtend) handleLeft.position.x = -5;
                  else handleLeft.position.x = 0;
                }
                const handleRight = BasicUtils.findFirstChild(handle + '_back:Right', this.config.model.base);
                if (handleRight != null) {
                  if (rightExtend) handleRight.position.x = 5 + Dimensions.CM(width);
                  else handleRight.position.x = Dimensions.CM(width);
                }
              }
            }
          }
        }

        this.applySettings(ApplyType.Depth);

        this.config.output.node.position.y = 0;

        // Fix Merge Bug...
        this._computeAllWorldMatrix();

        const nodes = new Array<BABYLON.TransformNode>();
        if (this.config.model.base) {
          if (this.config.model.device && this.config.model.device.model.buildBaseCorpus) {
            // Top
            // Corpus
            if (masterline || modular || modularNOL || (marine && height > 270)) {
              const corpus = (lwt ? 'Basic:' : '') + (this.get(PropertyType.Open) ? 'CorpusOpen' : 'Corpus');
              //console.log(lwt, corpus);
              nodes.push(...BasicUtils.findChildren(corpus + ':Center', this.config.model.base));
              if (!mergeCorpusLeft) nodes.push(...BasicUtils.findChildren(corpus + ':Left', this.config.model.base));
              // Special fpr LWT merge
              else if (this.getBlockItem() && this.getBlockItem().getBlockGroup()) {
                for (let i = 0; i < this.getBlockItem().getBlockGroup().getItems().length; i++) {
                  let prevItem;
                  if (
                    this.getBlockItem().getBlockGroup().getItems()[i] === this.getBlockItem() &&
                    (prevItem = this.getBlockItem().getBlockGroup().getItems()[i - 1]) &&
                    prevItem.getDeviceObject().style.indexOf('LWT') === 0
                  ) {
                    nodes.push(...BasicUtils.findChildren('DownUnder:Corpus:Left', this.config.model.base));
                    if (single && singleType == 'Free') nodes.push(...BasicUtils.findChildren('TopOver:BorderAngular_chamfer:Left', this.config.model.base));
                    else
                      nodes.push(
                        ...BasicUtils.findChildren('TopOver:BorderAngular_chamfer:Left:Extension', this.config.model.base),
                        ...BasicUtils.findChildren('TopOver:BorderAngular_chamfer:Left:Flex', this.config.model.base)
                      );
                  }
                }
              }
              if (!mergeCorpusRight) nodes.push(...BasicUtils.findChildren(corpus + ':Right', this.config.model.base));
              // Special fpr LWT merge
              else if (this.getBlockItem() && this.getBlockItem().getBlockGroup()) {
                for (let i = 0; i < this.getBlockItem().getBlockGroup().getItems().length; i++) {
                  let nextItem;
                  if (
                    this.getBlockItem().getBlockGroup().getItems()[i] === this.getBlockItem() &&
                    (nextItem = this.getBlockItem().getBlockGroup().getItems()[i + 1]) &&
                    nextItem.getDeviceObject().style.indexOf('LWT') === 0
                  ) {
                    nodes.push(...BasicUtils.findChildren('DownUnder:Corpus:Right', this.config.model.base));
                    if (single && singleType == 'Free') nodes.push(...BasicUtils.findChildren('TopOver:BorderAngular_chamfer:Right', this.config.model.base));
                    else
                      nodes.push(
                        ...BasicUtils.findChildren('TopOver:BorderAngular_chamfer:Right:Extension', this.config.model.base),
                        ...BasicUtils.findChildren('TopOver:BorderAngular_chamfer:Right:Flex', this.config.model.base)
                      );
                  }
                }
              }
              // CorpusSideCover
              if (sideCover) {
                const overflowLeft = this.getBlock().isBorderLeftOverflow();
                const overflowRight = this.getBlock().isBorderRightOverflow();
                if (left && (this.getBlockRow().getType() === 'Bottom' ? overflowLeft : overflowRight)) {
                  nodes.push(...BasicUtils.findChildren('CorpusSideCover:Left', this.config.model.base));
                  if (single && singleType === 'Free') nodes.push(...BasicUtils.findChildren('CorpusSideCover:LeftSingleFree', this.config.model.base));
                }
                if (right && (this.getBlockRow().getType() === 'Bottom' ? overflowRight : overflowLeft)) {
                  nodes.push(...BasicUtils.findChildren('CorpusSideCover:Right', this.config.model.base));
                  if (single && singleType === 'Free') nodes.push(...BasicUtils.findChildren('CorpusSideCover:RightSingleFree', this.config.model.base));
                }
              }
              if (lwt && !(single && singleType == 'Free')) {
                nodes.push(...BasicUtils.findChildren('CorpusLWT', this.config.model.base));
              }
            } else if (marine) {
              const corpus = 'DownUnder:Corpus';
              nodes.push(...BasicUtils.findChildren(corpus + ':Center', this.config.model.base));
              if (!mergeCorpusLeft) nodes.push(...BasicUtils.findChildren(corpus + ':Left', this.config.model.base));
              if (!mergeCorpusRight) nodes.push(...BasicUtils.findChildren(corpus + ':Right', this.config.model.base));
            }
            // Panel
            if (!this.containsOption('d2nB2') && !lwt) {
              nodes.push(BasicUtils.findFirstChild('Panel:Center', this.config.model.base));
              if (left || (this.blockItem && this.blockItem.prev() && this.blockItem.prev().getNode().containsOption('d2nB2')))
                nodes.push(BasicUtils.findFirstChild('Panel:Left', this.config.model.base));
              if (right || (this.blockItem && this.blockItem.next() && this.blockItem.next().getNode().containsOption('d2nB2')))
                nodes.push(BasicUtils.findFirstChild('Panel:Right', this.config.model.base));
            }
            // Panel Back
            if ((masterline || marine) && single && singleType === 'Free' && !lwt) {
              nodes.push(BasicUtils.findFirstChild('PanelBack:Center', this.config.model.base));
              if (left) {
                nodes.push(BasicUtils.findFirstChild('PanelBack:Left', this.config.model.base));
                // nodes.push(BasicUtils.findFirstChild('PanelSide:Left', this.config.model.base));
              }
              if (right) {
                nodes.push(BasicUtils.findFirstChild('PanelBack:Right', this.config.model.base));
                // nodes.push(BasicUtils.findFirstChild('PanelSide:Right', this.config.model.base));
              }
            }
            // Border
            if ((modular || modularNOL) && this.config.model.device.model.modularBorderIncluded) {
              // Who the hell need borders?
            } else {
              if (single && singleType === 'Free') {
                nodes.push(BasicUtils.findFirstChild(borderLeft + ':Center', this.config.model.base));
                if (left) nodes.push(BasicUtils.findFirstChild(borderLeft + ':Left', this.config.model.base));
                if (right) nodes.push(BasicUtils.findFirstChild(borderRight + ':Right', this.config.model.base));
              } else {
                nodes.push(BasicUtils.findFirstChild(borderLeft + ':Center:Flex', this.config.model.base));
                if (left) {
                  nodes.push(BasicUtils.findFirstChild(borderLeft + ':Left:Flex', this.config.model.base));
                  nodes.push(BasicUtils.findFirstChild(borderLeft + ':Left:Extension', this.config.model.base));
                }
                if (right) {
                  nodes.push(BasicUtils.findFirstChild(borderRight + ':Right:Flex', this.config.model.base));
                  nodes.push(BasicUtils.findFirstChild(borderRight + ':Right:Extension', this.config.model.base));
                }
              }
            }
            if (handle && (handleType === 'Both' || handleType === 'Full' || handleType === this.getBlockRow().getType())) {
              nodes.push(BasicUtils.findFirstChild(handle + ':Center', this.config.model.base));
              const handleLeft = this.settings.get(PropertyType.HandleLeft);
              const handleRight = this.settings.get(PropertyType.HandleRight);
              if (handleLeft) {
                if (
                  (borderLeft.indexOf('BorderRound') >= 0 || borderLeft.indexOf('Border45') >= 0) &&
                  handle === 'HandleRound' &&
                  handleType === 'Full' &&
                  left
                ) {
                  const bla = BasicUtils.findFirstChild('HandleRound_side:Left:Shared:BLA', this.config.model.base) as BABYLON.Mesh;
                  if (this.getBlock().getType() === 'Double') {
                    bla.isPickable = false;
                  } else {
                    bla.isPickable = true;
                  }
                  nodes.push(BasicUtils.findFirstChild('HandleRound_side:Left', this.config.model.base));
                } else {
                  nodes.push(BasicUtils.findFirstChild(handle + ':Left', this.config.model.base));
                }
                nodes.push(BasicUtils.findFirstChild('HandleMount:Left', this.config.model.base));
              }
              if (handleRight) {
                if (
                  (borderRight.indexOf('BorderRound') >= 0 || borderRight.indexOf('Border45') >= 0) &&
                  handle === 'HandleRound' &&
                  handleType === 'Full' &&
                  right
                ) {
                  const bla = BasicUtils.findFirstChild('HandleRound_side:Right:Shared:BLA', this.config.model.base) as BABYLON.Mesh;
                  if (this.getBlock().getType() === 'Double') {
                    bla.isPickable = false;
                  } else {
                    bla.isPickable = true;
                  }
                  nodes.push(BasicUtils.findFirstChild('HandleRound_side:Right', this.config.model.base));
                } else {
                  nodes.push(BasicUtils.findFirstChild(handle + ':Right', this.config.model.base));
                }
                nodes.push(BasicUtils.findFirstChild('HandleMount:Right', this.config.model.base));
              }
            }
            // Behind the Moon
            if (handle && handleBack && (handleType === 'Both' || handleType === 'Full' || handleType === 'Top')) {
              nodes.push(BasicUtils.findFirstChild(handle + '_back:Center', this.config.model.base));
              const handleLeft = this.settings.get(PropertyType.HandleLeft);
              const handleRight = this.settings.get(PropertyType.HandleRight);
              if (handleLeft) {
                nodes.push(BasicUtils.findFirstChild(handle + '_back:Left', this.config.model.base));
                nodes.push(BasicUtils.findFirstChild('HandleMount_back:Left', this.config.model.base));
              }
              if (handleRight) {
                nodes.push(BasicUtils.findFirstChild(handle + '_back:Right', this.config.model.base));
                nodes.push(BasicUtils.findFirstChild('HandleMount_back:Right', this.config.model.base));
              }
            }
          }
          // UpturnBack
          if ((single && singleType === 'Wall') || modular || modularNOL) {
            nodes.push(BasicUtils.findFirstChild(`${upturnBack}:Center`, this.config.model.base));
            if (left) nodes.push(BasicUtils.findFirstChild(`${upturnBack}:Left`, this.config.model.base));
            if (right) nodes.push(BasicUtils.findFirstChild(`${upturnBack}:Right`, this.config.model.base));
          }
          // Bottom
          // PedestrialCover
          if (masterline || modular || modularNOL) {
            if (bottom === 'Feet') {
              const feet = BasicUtils.findFirstChild('Feet', this.config.model.base);
              if (mergeCorpusLeft || mergeCorpusRight) {
                if (!mergeCorpusLeft) nodes.push(BasicUtils.findFirstChild('Left', feet));
                if (!mergeCorpusRight) nodes.push(BasicUtils.findFirstChild('Right', feet));
              } else {
                nodes.push(feet);
              }
            } else if (bottom === 'BaseMKN') {
              nodes.push(BasicUtils.findFirstChild('PedestrialCover:Center', this.config.model.base));
              if (left) nodes.push(BasicUtils.findFirstChild('PedestrialCover:Left', this.config.model.base));
              if (right) nodes.push(BasicUtils.findFirstChild('PedestrialCover:Right', this.config.model.base));
            }
          }
          // Corpus Backside Color
          if (single && singleType === 'Free' && (masterline || marine) && !this.get(PropertyType.Open)) {
            nodes.push(BasicUtils.findFirstChild('Corpus_backCover', this.config.model.base));
          }
        }

        // Add main
        if (this.config.model.main) {
          let splittedPart: BABYLON.TransformNode = null;
          if (!this._mainSplit) nodes.push(this.config.model.main);
          else if (depth === 700 && (splittedPart = BasicUtils.findFirstChild('700', this.config.model.main))) nodes.push(splittedPart);
          else if (depth === 850 && (splittedPart = BasicUtils.findFirstChild('850', this.config.model.main))) nodes.push(splittedPart);
          if (depth === 850 && this.config.model.device && this.config.model.device.model.extendCover && this.config.model.base) {
            // Cover
            nodes.push(BasicUtils.findFirstChild('CoverSplit', this.config.model.base));
          }
        } else if (this.config.model.base) {
          // Cover
          nodes.push(BasicUtils.findFirstChild('Cover', this.config.model.base));
        }

        // Change Texture on FlexiChefs
        if (flexiChef) {
          const mat = spaceClean ? this.getScene().getMaterialByName('_folie_spaceclean1') : this.getScene().getMaterialByName('_folie_spaceclean1_off');
          const mat_m = spaceClean ? this.getScene().getMaterialByName('_folie_spaceclean1_m') : this.getScene().getMaterialByName('_folie_spaceclean1_off_m');
          // const node = BasicUtils.findFirstChild('sc_logo:klappe', this.config.model.node) as BABYLON.Mesh;
          // if (node) node.material = mat;
          BasicUtils.findChildren('sc_logo:klappe', this.config.model.node).forEach(n => {
            const mesh = n as BABYLON.Mesh;
            if (mesh && mesh.material) {
              if (mesh.material.name.endsWith('_m')) mesh.material = mat_m;
              else mesh.material = mat;
            }
          });
        }

        // Fix Door Handles on SpaceCombi
        if (spaceCombi) {
          if (this.config.model.main) {
            // Danke Eugen. doorL ist Türgrif Rechts und doorR ist Türgrif Links !!!
            BasicUtils.findFirstChild('doorL', this.config.model.main)
              ?.getChildMeshes()
              .forEach(m => (m.isPickable = spaceCombiDoorR));
            BasicUtils.findFirstChild('doorR', this.config.model.main)
              ?.getChildMeshes()
              .forEach(m => (m.isPickable = !spaceCombiDoorR));
          }
        }

        // Add all options
        const options = this.config.model.options.values();
        let option: OptionNode = null;
        while (typeof (option = options.next().value) !== 'undefined') {
          if (option.getEquipment()) {
            let deviceSubtype: string | Subtype = option.getEquipment().getDeviceObject().subtype;
            if (typeof deviceSubtype === 'string') deviceSubtype = Subtype[deviceSubtype];
            if (deviceSubtype === Subtype.Underframe850) {
              underframeOffset = 85;
              option.position.y = -underframeOffset;
              this._computeAllWorldMatrix();
            } else if (deviceSubtype === Subtype.Underframe580) {
              underframeOffset = 58;
              option.position.y = -underframeOffset;
              this._computeAllWorldMatrix();
            }
          }

          // MKN Badge
          if (mergeCorpusRight && option.getDeviceComponent().component.id === 'VKx8K') continue;
          if (mergeCorpusRight && option.getDeviceComponent().component.id === '2NgV4') continue;
          if ((masterline || marine) && !right && this.blockItem && option.getDeviceComponent().component.id === 'VKx8K') continue;
          if ((masterline || marine) && !right && this.blockItem && option.getDeviceComponent().component.id === '2NgV4') continue;
          // FrontCover
          if ((mergeCorpusRight || mergeCorpusLeft) && option.getDeviceComponent().component.id === 'aL3z4') continue;
          // Check Backside
          if (option.getDeviceComponent().backside) {
            if (this.get(PropertyType.DoubleSidedOperation)) {
              option.scaling.z = -1;
              option.position.z = -80;
              BasicUtils.computeAllWorldMatrix(option);
            } else {
              option.scaling.z = 1;
              if (!option.getDeviceComponent().fixed) {
                switch (this.settings.get(PropertyType.Depth) as number) {
                  case 700:
                    option.position.z = 15;
                    break;
                  case 850:
                  default:
                    option.position.z = 0;
                    break;
                }
              }
              BasicUtils.computeAllWorldMatrix(option);
            }
          }
          // Hygiene
          if (option.getDeviceComponent().component.id === 'OK7l2') {
            if (this.get(PropertyType.Open)) nodes.push(BasicUtils.findFirstChild('HygieneOpen', option));
            else nodes.push(BasicUtils.findFirstChild('HygieneClosed', option));
            continue;
          }
          // WingedDoor
          if (option.getDeviceComponent().component.id === '7KP0K') {
            nodes.push(BasicUtils.findFirstChild('Front', option));
            if (this.get(PropertyType.Open)) nodes.push(BasicUtils.findFirstChild('Back', option));
            continue;
          }

          const meshes = option.getChildMeshes();
          for (let j = 0; j < meshes.length; j++) {
            const mesh = meshes[j];
            nodes.push(mesh);
          }
        }

        // Merge
        const materialMap = new Map<string, BABYLON.Mesh[]>();
        const materialMapKeys = new Array<string>();
        for (let i = 0; i < nodes.length; i++) {
          const element = nodes[i];
          const childMeshes = element.getChildMeshes();
          for (let i = 0; i < childMeshes.length; i++) {
            const mesh = childMeshes[i];
            if (!mesh.isPickable) continue;
            // console.log('check', mesh.name, mesh.material ? mesh.material.name : '', mesh);
            if (mesh instanceof BABYLON.Mesh && mesh.material) {
              switch (this.settings.get(PropertyType.Depth) as number) {
                case 700:
                  if (mesh.parent.name === 'Extension') {
                    continue;
                  }
                  break;
                case 850:
                  break;
              }
              if (single && mesh.parent.name === 'Fill') continue;
              if (mesh.material.name === '_placeholder' || mesh.material.name.startsWith('material')) continue;
              if (materialMap.has(mesh.material.name)) {
                materialMap.get(mesh.material.name).push(mesh);
              } else {
                materialMap.set(mesh.material.name, [mesh]);
                materialMapKeys.push(mesh.material.name);
              }
            }
          }
        }
        let metal: BABYLON.Mesh = null;
        let eraser: BABYLON.Mesh = null;
        let cut: BABYLON.Mesh = null;
        const mergedOnes = [];
        for (let i = 0; i < materialMapKeys.length; i++) {
          // console.log('.....................................');
          const mat = materialMapKeys[i];
          const meshes = materialMap.get(mat);
          // console.log('merge', mat, meshes);
          // const mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true);
          // if (upperstructureOnly) {
          //   const box = BABYLON.MeshBuilder.CreateBox('eraser', { width: 200, depth: 200, height: 115 });
          //   const csgBox = BABYLON.CSG.FromMesh(box);
          //   for (let i = 0; i < meshes.length; i++) {
          //     const mesh = meshes[i];
          //     if (mesh.intersectsMesh(box)) {
          //       const csgMesh = BABYLON.CSG.FromMesh(mesh).inverse().subtract(csgBox).inverse();
          //       const newMesh = csgMesh.toMesh('csg-' + mesh.name, mesh.material, mesh.getScene(), true);
          //       meshes[i] = newMesh;
          //     }
          //   }
          //   box.dispose();
          // }
          if (upperstructureOnly) {
            const box = BABYLON.MeshBuilder.CreateBox('eraser', {
              width: this.config.model.device.model.width,
              depth: Math.max(...this.config.model.device.model.depths),
              height: 115
            });
            for (let i = 0; i < meshes.length; ) {
              const mesh = meshes[i];
              if (mesh.intersectsMesh(box)) {
                meshes.splice(i, 1);
              } else {
                i++;
              }
            }
            box.dispose();
          }

          if (meshes.length < 1) continue;
          let mergedMesh = BABYLON.Mesh.MergeMeshes(meshes, false, true, undefined, false);
          mergedMesh.name = 'mesh-' + mat;
          // meshes.forEach(m => {
          //   if (m.name.startsWith('csg-')) m.dispose();
          // });
          // if (upperstructureOnly) {
          //   const box = BABYLON.MeshBuilder.CreateBox('eraser', { width: 200, depth: 200, height: 115 });
          //   const csgMesh = BABYLON.CSG.FromMesh(mergedMesh).inverse().subtract(BABYLON.CSG.FromMesh(box)).inverse();
          //   const newMesh = csgMesh.toMesh(mergedMesh.name, mergedMesh.material, mergedMesh.getScene(), true);
          //   mergedMesh.dispose();
          //   box.dispose();
          //   mergedMesh = newMesh;
          // }
          // mergedMesh.parent = this.config.output.node;
          mergedOnes.push(mergedMesh);
          for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
            const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
            shadowGenerator.addShadowCaster(mergedMesh);
          }
          for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
            const mirror = DeviceNode.defaultSettings.mirrors[i];
            mirror.renderList.push(mergedMesh);
          }
          mergedMesh.receiveShadows = true;
          if (mergedMesh.name === 'mesh-_metal') {
            metal = mergedMesh;
          } else if (mergedMesh.name === 'mesh-eraser') {
            eraser = mergedMesh;
          } else if (blockItem && (masterline || marine) && this.getBlock().isFullBlendColor() && mergedMesh.name === 'mesh-_metal_blue_panel') {
            mergedMesh.material = this.getBlock().getBlendColorMaterial();
          } else if (blockItem && (masterline || marine) && mergedMesh.name === 'mesh-_metal_blue_panel_top') {
            mergedMesh.material = this.getBlock().getBlendColorMaterial();
            cut = mergedMesh;
          } else if (blockItem && (masterline || marine) && mergedMesh.name === 'mesh-_masterknob') {
            mergedMesh.material = this.getBlock().getSteelKnobMaterial();
          } else if (blockItem && (masterline || marine) && mergedMesh.name === 'mesh-_metal_substructure') {
            mergedMesh.material = this.getBlock().getDoorColorMaterial();
          }

          if (blockItem) mergedMesh.renderOverlay = this.getBlockItem().isError();
        }
        // Erase from Metal (Das richtig und bleibt stehen)
        if (metal && eraser) {
          const csgMesh = BABYLON.CSG.FromMesh(metal).inverse().subtract(BABYLON.CSG.FromMesh(eraser)).inverse();
          const newMesh = csgMesh.toMesh(metal.name + '-erased', metal.material, metal.getScene(), true);

          newMesh.parent = this.config.output.node;
          for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
            const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
            shadowGenerator.addShadowCaster(newMesh);
          }
          for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
            const mirror = DeviceNode.defaultSettings.mirrors[i];
            mirror.renderList.push(newMesh);
          }
          newMesh.receiveShadows = true;

          metal.dispose();
          eraser.dispose();

          metal = newMesh;
        }

        if (metal && cutOut) {
          const box = BABYLON.MeshBuilder.CreateBox('cut', {
            width: (this.blockItem.getWidth() - 100 - cutOut.l - cutOut.r) / 10,
            depth: (this.blockItem.getDepth() - cutOut.b - cutOut.t) / 10,
            height: 10
          });
          const movePosSide = cutOut.r + 50 - (cutOut.l + 50);
          const movePosBack = cutOut.b - (cutOut.t + 50);
          box.position.x = (this.blockItem.getWidth() + movePosSide) / 20;
          box.position.y = 85;
          box.position.z = (-this.blockItem.getDepth() + movePosBack) / 20;

          const metalCSG = BABYLON.CSG.FromMesh(metal);
          const boxCSG = BABYLON.CSG.FromMesh(box);

          const resultCSG = metalCSG.inverse().subtract(boxCSG).inverse();
          const resultMesh = resultCSG.toMesh(metal.name + '-cutOut', metal.material, metal.getScene(), true);

          resultMesh.parent = this.config.output.node;
          for (let i = 0; i < DeviceNode.defaultSettings.shadowGenerators.length; i++) {
            const shadowGenerator = DeviceNode.defaultSettings.shadowGenerators[i];
            shadowGenerator.addShadowCaster(resultMesh);
          }
          for (let i = 0; i < DeviceNode.defaultSettings.mirrors.length; i++) {
            const mirror = DeviceNode.defaultSettings.mirrors[i];
            mirror.renderList.push(resultMesh);
          }
          resultMesh.receiveShadows = true;

          metal.dispose();
          box.dispose();
        }

        // Set parent of merged meshes
        mergedOnes.forEach(mergedMesh => {
          mergedMesh.parent = this.config.output.node;

          if (marine || modular || modularNOL || masterline) {
            const overflowBack = 2.6258; // Magic Number from Eugen
            mergedMesh.scaling.z = depth / (depth + overflowBack - 10);
          }
        });

        this.extended(extendedSize);

        this.config.output.node.position.y = underframeOffset;
      }

      const bounds = this.getBounds();
      // console.log('Merged Bounds', bounds);
      // Add Marks
      this.config.output.mark = MarkUtils.buildMark({
        width: bounds.width,
        height: bounds.height,
        depth: bounds.depth,
        offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
        parent: this.config.output.node
      });
      this.config.output.mark.setEnabled(this.get(PropertyType.Mark) as boolean);
      this.config.output.markFull = MarkUtils.buildFullMark({
        width: bounds.width,
        height: bounds.height,
        depth: bounds.depth,
        offset: new BABYLON.Vector3(bounds.x.min, bounds.y.min, bounds.z.max),
        parent: this.config.output.node
      });
      this.config.output.markFull.setEnabled(this.get(PropertyType.MarkFull) as boolean);
      // Add Label
      this.config.output.label = LabelUtils.drawLabel('' + width, Dimensions.CM(width), Orientation.Bottom);
      if (this.get(PropertyType.Rotate)) BasicUtils.findFirstChild('text', this.config.output.label).rotation.y = Math.PI;
      this.config.output.label.parent = this.config.output.node;
      this.config.output.label.position.z = -Dimensions.CM(depth);
      this.config.output.label.setEnabled(this.get(PropertyType.Label) as boolean);
      if (this.getFreeItem()) {
        {
          const depth = this.getFreeItem().getDeviceObject().model.depths[0];
          const label = LabelUtils.drawLabel('' + depth, Dimensions.CM(depth), Orientation.Right);
          label.parent = this.config.output.label;
          label.position.x = Dimensions.CM(width);
        }
        {
          let height = this.getFreeItem().getDeviceObject().model.height;
          let heightOffset = 0;
          this.getFreeItem()
            .getEquipments()
            .forEach(e => {
              switch (toSubtype(e.getDeviceObject().subtype)) {
                case Subtype.Underframe580:
                case Subtype.Underframe850:
                  height += e.getDeviceObject().model.height;
                  heightOffset -= e.getDeviceObject().model.height;
                  break;
                case Subtype.FlexiCombiAir:
                  height += e.getDeviceObject().model.height;
                  break;
              }
            });
          if (this.getFreeItem().getDeviceObject().model.flexiChef) height += 150;

          const label = LabelUtils.drawLabel('' + height, Dimensions.CM(height), Orientation.Right);
          label.parent = this.config.output.label;
          label.position.x = Dimensions.CM(width);
          label.rotation.x = -Math.PI / 2;

          this.config.output.label.position.y = Dimensions.CM(heightOffset);
        }
      }

      return true;
    });
  }

  public extended(extendedSize: number) {
    if (extendedSize > 0) {
      const depth = this.settings.get(PropertyType.Depth) as number;
      const scale = (depth + extendedSize) / depth;
      this.config.output.node.scaling.z = scale;
      if (this.getBlockItem() && this.getBlockRow().getType() === 'Top')
        this.config.output.node.position.z = -Dimensions.CM(this.getBlockRow().getDepthExtended());
    } else {
      this.config.output.node.scaling.z = 1;
    }
  }

  private _computeAllWorldMatrix() {
    const childs = this.config.model.node.getChildTransformNodes();
    for (let i = 0; i < childs.length; i++) {
      const child = childs[i];
      child.computeWorldMatrix();
    }
  }

  dispose() {
    this.config.model.node.dispose();
    super.dispose();
  }
}
